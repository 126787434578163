import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import css from 'classnames';

import { ReactComponent as ActivateAccount } from 'assets/activateAccount.svg';
import { ReactComponent as AddCreators } from 'assets/addCreators.svg';
import { ReactComponent as AddFunds } from 'assets/addFunds.svg';
import { ReactComponent as SendPayments } from 'assets/sendPayments.svg';
import Card from 'components/Card';
import Button from 'components/Button';
import global from 'styles/global';
import { setIsOnboardingDismissed } from 'slices/misc';

const useStyles = makeStyles((theme) => ({
  stepNumber: {
    backgroundColor: theme.palette.shades.white,
    borderRadius: '50%',
    height: '32px',
    width: '32px',
  },
  active: {
    backgroundColor: `${theme.palette.custom.payBlue} !important`,
    color: theme.palette.shades.white,
  },
  completed: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.shades.white,
  },
  image: {
    height: 192,
  },
  line: {
    flex: 1,
    height: 0,
    borderBottomColor: theme.palette.shades.charcoal036,
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
  },
  stepLink: {
    cursor: 'pointer',
  },
  closeIcon: {
    paddingBottom: '5px',
  },
}));

const steps = [
  {
    name: 'creators',
    label: 'Add Creators',
    subheading: `Add your roster so you can send them payments, include them in campaigns, manage their asset ownership and more!`,
    buttonText: 'Go to Creators',
    path: '/creators',
  },
  {
    name: 'funds',
    label: 'Add funds',
    subheading:
      'Fund your Creator Pay balance by transferring funds from your bank account or setting up direct deposit into your account!',
    buttonText: 'Go to Funds',
    path: '/funds',
  },
  {
    name: 'campaigns',
    label: 'Start a Campaign',
    subheading: `Track your Campaign's income and pay your Creators for their work!`,
    buttonText: 'Go to Campaigns',
    path: '/campaigns',
  },
];

const OnboardingSteps = ({
  activeIndex,
  activateAction,
  setActiveStep,
  isCompletedMap,
}) => {
  const g = global();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const activeStep = steps[activeIndex] || {};

  const navToPage = (path) => {
    history.push(path);
  };

  return (
    <Card
      title="Setup your account"
      action={
        <IconButton
          className={css(g.p_zero, classes.closeIcon)}
          onClick={() => dispatch(setIsOnboardingDismissed(true))}
        >
          <CloseIcon />
        </IconButton>
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} className={g.mb_lg}>
            <Grid item xs={2} />
            <Grid item xs={8} className={g.flexRowEven}>
              {steps.map((step, index) => (
                <React.Fragment key={'onboarding-steps-' + index}>
                  {index !== 0 && (
                    <div
                      className={css(classes.line, g.mt_md, g.full_width)}
                    ></div>
                  )}
                  <div
                    item
                    className={css(g.centerChildren, classes.stepLink)}
                    onClick={() => setActiveStep(index)}
                  >
                    <Typography
                      className={[
                        classes.stepNumber,
                        g.centerChildren,
                        g.mb_sm,
                        activeIndex === index && classes.active,
                        isCompletedMap[step.name] && classes.completed,
                      ]}
                      variant="h3"
                    >
                      {isCompletedMap[step.name] ? <DoneIcon /> : index + 1}
                    </Typography>
                    <Typography variant="caption">
                      {steps[index].label}
                    </Typography>
                  </div>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} className={g.centerChildren}>
                  <Typography variant="body2" className={g.textCenter}>
                    {activeStep.subheading}
                  </Typography>
                </Grid>

                <Grid
                  item
                  md={12}
                  className={css(g.full_width, g.centerChildren, g.mb_md)}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={
                      activeStep.path
                        ? () => navToPage(activeStep.path)
                        : activateAction
                    }
                  >
                    {activeStep.buttonText}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};
export default OnboardingSteps;
