import { handleError } from 'api/utils';
import { createRequest } from 'api/request';

export const getUserCreators = async (state) => {
  return createRequest(`/v1/creators/user`, state, {
      method: 'GET',
      vgs: false,
    }, true).then((res) => handleError(res));
};

export const updateCreator = async (state, data) => {
  // Create a copy of the data object
  const requestData = { ...data };
  
  // Remove tax_id and account_number if they start with 'tok_'
  if (requestData.tax_id?.startsWith('tok_')) {
    delete requestData.tax_id;
  }
  if (requestData.account_number?.startsWith('tok_')) {
    delete requestData.account_number;
  }
  
  return createRequest(`/v1/creators/user/${data.id}`, state, {
      method: 'PUT',
      body: requestData,
      vgs: true,
    }).then((res) => handleError(res));
};
