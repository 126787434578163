import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import css from 'classnames';

import { emptyPaymentGroupData } from 'fixtures/paymentGroups';
import objectStates from 'constants/objectStates';
import { currentUserSelector } from 'slices/user';
import {
  initiatePayments,
  paymentsSelector,
  paymentsDictSelector,
  initiatePaymentsSelector,
} from 'slices/payments';
import { outboundPmtFeesSelector } from 'slices/orgFees';
import TableHeader from 'components/TableHeader';
import Button from 'components/Button';
import PaymentConfirmationModal from 'components/PaymentConfirmationModal';
import global from 'styles/global';
import PaymentsTable from 'components/PaymentsTable';

export default function PaymentsTab() {
  const g = global();
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const group = useSelector((state) => {
    return (
      state.paymentGroups.paymentGroups.data.find(
        (grp) => grp.id === groupId
      ) || emptyPaymentGroupData
    );
  });
  const history = useHistory();

  const { data: payments } = useSelector(paymentsSelector);
  const paymentsDict = useSelector(paymentsDictSelector);
  const outboundPaymentFees = useSelector(outboundPmtFeesSelector);
  const initiatePaymentsState = useSelector(initiatePaymentsSelector);
  const { data: user } = useSelector(currentUserSelector);

  const [selectedPayments, setSelectedPayments] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const executePayments = () => {
    dispatch(
      initiatePayments(selectedPayments, () => setModalVisible(false), {
        payment_group_id: groupId,
      })
    );
  };

  const navToNewPayment = () => {
    history.push(`/payments/newPayment?payment_group_id=${groupId}`);
  };

  return (
    <>
      <PaymentConfirmationModal
        open={modalVisible}
        handleClose={() => setModalVisible(false)}
        executePayments={executePayments}
        payments={selectedPayments}
        paymentsDict={paymentsDict}
        outboundPaymentFees={outboundPaymentFees}
        initiatePaymentsState={initiatePaymentsState}
      />

      <TableHeader title={`Payments in ${group.title}`} className={g.mt_lg}>
        <div>
          <Button
            size="small"
            className={g.ml_xs}
            variant="outlined"
            color="primary"
            onClick={() => setModalVisible(true)}
            disabled={
              selectedPayments.length === 0 || user.role === 'collaborator'
            }
          >
            Execute Payments
          </Button>

          <Button
            size="small"
            className={g.ml_xs}
            onClick={navToNewPayment}
            type="submit"
            variant="contained"
            color="primary"
          >
            New Payment
          </Button>
        </div>
      </TableHeader>
      <div className={css(g.tableWrapper, g.mb_xl)}>
        <PaymentsTable
          payments={payments}
          columnsToDisplay={[
            'recipient',
            'amount',
            'recipient_role',
            'status',
            'memo',
            'link',
            'last_updated'
          ]}
          checkboxEnabled
          onSelectionModelChange={setSelectedPayments}
          isRowSelectable={(params) =>
            params.row.status === objectStates.draft ||
            params.row.status === objectStates.requires_info
          }
        />
      </div>
    </>
  );
}
