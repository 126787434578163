import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import TabPanel from 'components/TabPanel';
import LoadingOverlay from 'components/LoadingOverlay';
import ModalConfirm from 'components/ModalConfirm';

import { setHeaderData } from 'slices/misc';
import {
  getPaymentGroups as getPaymentGroupsSlice,
  paymentGroupsSelector,
} from 'slices/paymentGroups';
import {
  creatorsSelector,
  getCreators as getCreatorsSlice,
  creatorsDictSelector,
} from 'slices/user';
import {
  getOrgFees as getOrgFeesSlice,
  orgFeesSelector,
  outboundPmtFeesSelector,
} from 'slices/orgFees';
import { billingAuthSelector } from 'slices/org';
import {
  getPayments as getPaymentsSlice,
  initiatePayments as initiatePaymentsSlice,
  remindPayments as remindPaymentsSlice,
  deletePayments as deletePaymentsSlice,
  paymentsSelector,
  paymentsDictSelector,
  draftPaymentsSelector,
  erroredPaymentsSelector,
  initiatedPaymentsSelector,
  initiatePaymentsSelector,
  postedPaymentsSelector,
  remindPaymentsSelector,
  deletePaymentsSelector,
} from 'slices/payments';
import { hasTreasurySelector } from 'slices/org';
import TableHeader from 'components/TableHeader';
import PaymentsTable from 'components/PaymentsTable';
import Button from 'components/Button';
import Tabs from 'components/Tabs';
import global from 'styles/global';
import BillingAuthModal from 'components/BillingAuthModal';
import PaymentConfirmationModal from 'components/PaymentConfirmationModal';
import objectStates from 'constants/objectStates';
import { exportPayments } from 'util/csvExports';
import { currentUserSelector } from 'slices/user';
import { getMembers as getMembersSlice, adminsSelector } from 'slices/user';


const FAILED = 'FAILED';
const POSTED = 'POSTED';
const INDEX = 'INDEX';


function Payments({
  creatorsDict,
  creatorsState,
  draftPayments,
  erroredPayments,
  getCreators,
  getOrgFees,
  getPaymentGroups,
  getPayments,
  initiatedPayments,
  initiatePayments,
  initiatePaymentsState,
  orgFeesState,
  outboundPaymentFees,
  paymentGroupsState,
  paymentsDict,
  paymentsState,
  postedPayments,
  remindPayments,
  remindPaymentsState,
  deletePayments,
  deletePaymentsState,
  hasTreasury,
  getMembers,
  adminsState,
}) {
  const TAB_QUERY_PARAMS = [
    'draft_payments',
    'payments',
    'posted_payments',
    'all_payments',
  ];

  const TABLE_TITLES = ['All', 'Posted', 'Initiated', 'Error', 'Draft'];
  const DEFAULT_PAYMENTS_TABLE_COLUMNS = ['id', 'recipient', 'amount', 'status', 'payment_group_id', 'memo', 'created_at']
  const INDEX_PAYMENTS_TABLE_COLUMNS = ['last_updated']
  const POSTED_PAYMENTS_TABLE_COLUMNS = ['date_posted']
  const FAILED_PAYMENTS_TABLE_COLUMNS = ['date_failed']


  const dispatch = useDispatch();
  const g = global();
  const history = useHistory();

  const { isDelinquent } = useSelector(billingAuthSelector);
  const { data: user } = useSelector(currentUserSelector);
  const { loading: orgFeesLoading } = orgFeesState;
  const { loading: paymentGroupsLoading, data: paymentGroups } =
    paymentGroupsState;
  const { loading: paymentsLoading, data: allPayments } = paymentsState;
  const { loading: creatorsLoading } = creatorsState;
  const { loading: remindLoading } = remindPaymentsState;
  const { loading: deleteLoading } = deletePaymentsState;
  const { loading: adminsLoading } = adminsState;
  const loading =
    paymentGroupsLoading ||
    paymentsLoading ||
    creatorsLoading ||
    orgFeesLoading ||
    adminsLoading;

  const query = new URLSearchParams(useLocation().search);
  const tab = query.get('tab');

  let selectedTab = TAB_QUERY_PARAMS.indexOf(tab);

  if (!tab) {
    selectedTab = 0;
  } else if (tab && selectedTab < 0) {
    selectedTab = 0;
    history.push('/payments');
  }

  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [billingModalVisible, setBillingModalVisible] = useState(false);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [currentTab, setCurrentTab] = useState(selectedTab);

  useEffect(() => {
    dispatch(
      setHeaderData({
        title: 'Payments',
        breadcrumbs: [
          { label: 'Creator Pay', link: '/' },
          { label: 'Payments' },
        ],
      })
    );

    getPaymentGroups();
    getPayments();
    getCreators();
    getMembers();
    getOrgFees();
  }, []);

  const changeTab = (_, newValue) => {
    setCurrentTab(newValue);
    setSelectedPayments([]);
    history.push(`/payments?tab=${TAB_QUERY_PARAMS[newValue]}`);
  };

  const navToNewPayment = () => {
    history.push('/payments/newPayment');
  };

  const handleClickExecute = () => {
    if (isDelinquent) {
      setBillingModalVisible(true);
      return;
    }
    setModalVisible(true);
  };

  const executePayments = () => {
    initiatePayments(selectedPayments, () => {
      setModalVisible(false);
      changeTab(null, 1);
    });
  };

  const handleDelete = () => {
    deletePayments(selectedPayments);
  };

  const handleExportPayments = () => {
    exportPayments(allPayments, paymentGroups);
  };

  const sendReminder = async (method) => {
    remindPayments(selectedPayments, method);
  };

  const smsDisabled = () => {
    let disabled = false;
    if (selectedPayments.length === 0) {
      disabled = true;
    }
    selectedPayments.forEach((payment) => {
      const recipient = paymentsDict[payment]?.recipient;
      if (!creatorsDict[recipient]?.phone_number) {
        disabled = true;
      }
    });

    return disabled;
  };

  const deleteDisabled = () => {
    let disabled = false;
    if (selectedPayments.length === 0) {
      disabled = true;
    }
    selectedPayments.forEach((payment) => {
      const status = paymentsDict[payment]?.status;
      if (
        status !== objectStates.requires_info &&
        status !== objectStates.draft
      ) {
        disabled = true;
      }
    });

    return disabled;
  }

  const paymentTableHeaders = (status) => {
    if (!status) {
      return [...DEFAULT_PAYMENTS_TABLE_COLUMNS, ...INDEX_PAYMENTS_TABLE_COLUMNS]
    }

    if (status === INDEX) {
      return [...DEFAULT_PAYMENTS_TABLE_COLUMNS, ...INDEX_PAYMENTS_TABLE_COLUMNS]
    }
    if (status === POSTED) {
      return [...DEFAULT_PAYMENTS_TABLE_COLUMNS, ...POSTED_PAYMENTS_TABLE_COLUMNS]
    }

    if (status === FAILED) {
      return [...DEFAULT_PAYMENTS_TABLE_COLUMNS, ...FAILED_PAYMENTS_TABLE_COLUMNS]
    }
  }


  const tableHeader = (
    <TableHeader title={`${TABLE_TITLES[currentTab]} Payments`}>
      <div>
        {(currentTab === 4 || currentTab === 2) && (
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => setDeleteModalVisible(true)}
            disabled={deleteDisabled()}
            loading={deleteLoading}
          >
            Delete Payments
          </Button>
        )}
        {currentTab === 4 && (
          <Button
            size="small"
            className={g.ml_xs}
            variant="outlined"
            color="primary"
            onClick={handleClickExecute}
            disabled={
              selectedPayments.length === 0 || user.role === 'collaborator'
            }
          >
            Execute Payments
          </Button>
        )}
        {currentTab === 2 && (
          <>
            <Button
              size="small"
              className={g.ml_xs}
              onClick={() => sendReminder('sms')}
              disabled={smsDisabled()}
              loading={remindLoading}
              type="submit"
              variant="outlined"
              color="primary"
            >
              Send SMS reminder
            </Button>
            <Button
              size="small"
              className={g.ml_xs}
              onClick={() => sendReminder('email')}
              disabled={selectedPayments.length === 0}
              loading={remindLoading}
              type="submit"
              variant="outlined"
              color="primary"
            >
              Send email reminder
            </Button>
          </>
        )}
        <Button
          size="small"
          className={g.ml_xs}
          onClick={navToNewPayment}
          type="submit"
          variant="contained"
          color="primary"
          disabled={!hasTreasury}
        >
          New Payment
        </Button>
      </div>
    </TableHeader>
  );

  return (
    <div>
      <LoadingOverlay open={loading} />

      <BillingAuthModal
        open={billingModalVisible}
        onClose={() => setBillingModalVisible(false)}
        isDelinquent
      />

      <PaymentConfirmationModal
        open={modalVisible}
        handleClose={() => setModalVisible(false)}
        executePayments={executePayments}
        payments={selectedPayments}
        paymentsDict={paymentsDict}
        outboundPaymentFees={outboundPaymentFees}
        initiatePaymentsState={initiatePaymentsState}
      />

      <ModalConfirm
        open={deleteModalVisible}
        handleClose={() => setDeleteModalVisible(false)}
        callback={handleDelete}
        title="Confirm deletion?"
        bodyText={`Are you sure you want to delete ${selectedPayments.length > 1
          ? `these ${selectedPayments.length} payments?`
          : 'this payment?'
          }`}
        buttonText="Confirm"
      />

      <Tabs
        tabLabels={TABLE_TITLES}
        value={currentTab}
        onChange={changeTab}
        aria-label="payments-tabpanel"
      />

      <TabPanel value={currentTab} index={0}>
        {tableHeader}
        <PaymentsTable
          payments={allPayments}
          columnsToDisplay={paymentTableHeaders(INDEX)}
        />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        {tableHeader}
        <PaymentsTable
          payments={postedPayments}
          columnsToDisplay={paymentTableHeaders(POSTED)}
        />
      </TabPanel>

      <TabPanel value={currentTab} index={2}>
        {tableHeader}
        <PaymentsTable
          payments={initiatedPayments}
          columnsToDisplay={paymentTableHeaders()}
          checkboxEnabled
          onSelectionModelChange={setSelectedPayments}
          isRowSelectable={(params) =>
            params.row.status === objectStates.requires_info
          }
        />
      </TabPanel>

      <TabPanel value={currentTab} index={3}>
        {tableHeader}
        <PaymentsTable
          payments={erroredPayments}
          columnsToDisplay={paymentTableHeaders(FAILED)}
          checkboxEnabled
          onSelectionModelChange={setSelectedPayments}
          isRowSelectable={(params) =>
            params.row.status === objectStates.requires_info
          }
        />
      </TabPanel>

      <TabPanel value={currentTab} index={4}>
        {tableHeader}

        <PaymentsTable
          payments={draftPayments}
          handleExportPayments={handleExportPayments}
          columnsToDisplay={paymentTableHeaders()}
          checkboxEnabled
          onSelectionModelChange={setSelectedPayments}
        />
      </TabPanel>
    </div>
  );
}

const mapStateToProps = (state) => ({
  creatorsDict: creatorsDictSelector(state),
  creatorsState: creatorsSelector(state),
  draftPayments: draftPaymentsSelector(state),
  initiatedPayments: initiatedPaymentsSelector(state),
  erroredPayments: erroredPaymentsSelector(state),
  postedPayments: postedPaymentsSelector(state),
  orgFeesState: orgFeesSelector(state),
  paymentGroupsState: paymentGroupsSelector(state),
  paymentsDict: paymentsDictSelector(state),
  paymentsState: paymentsSelector(state),
  outboundPaymentFees: outboundPmtFeesSelector(state),
  initiatePaymentsState: initiatePaymentsSelector(state),
  remindPaymentsState: remindPaymentsSelector(state),
  deletePaymentsState: deletePaymentsSelector(state),
  hasTreasury: hasTreasurySelector(state),
  adminsState: adminsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  initiatePayments: (data, cb) => dispatch(initiatePaymentsSlice(data, cb)),
  getCreators: () => dispatch(getCreatorsSlice()),
  getOrgFees: () => dispatch(getOrgFeesSlice()),
  getPaymentGroups: () => dispatch(getPaymentGroupsSlice()),
  getPayments: () => dispatch(getPaymentsSlice()),
  remindPayments: (ids, method) => dispatch(remindPaymentsSlice(ids, method)),
  deletePayments: (data) => dispatch(deletePaymentsSlice(data)),
  getMembers: () => dispatch(getMembersSlice()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
