export const createParams = (params) => {
  if (!params) return '';

  const toStr = (val) => {
    if (!val && val !== 0) {
      return '';
    }
    if (val instanceof Date) {
      return val.toISOString();
    }
    return val.toString();
  };
  const str = Object.keys(params).reduce((agg, key, idx) => {
    if (idx === 0) {
      agg += `?${key}=${toStr(params[key])}`;
    } else {
      agg += `&${key}=${toStr(params[key])}`;
    }
    return agg;
  }, '');
  return str;
};

export const domain = () => {
  if (process.env.REACT_APP_DEMO) return 'https://dev.creatorpay.co';
  return process.env.NODE_ENV === 'development'
    ? 'http://localhost:2050'
    : 'https://api.creatorpay.co';
};

export const nodeDomain = () => {
  if (process.env.REACT_APP_DEMO) return 'https://nodeApiDemo.creatorpay.co';
  return process.env.NODE_ENV === 'development'
    ? 'http://localhost:3001'
    : 'https://nodeApi.creatorpay.co';
};

export const pdfDomain = () => {
  // todo demo domain?
  return process.env.NODE_ENV === 'development'
    ? 'http://localhost:3031'
    : 'https://pdf.creatorpay.co'; // todo switch to creatorpay domain
};

// Locally to test you will still need to switch this to use the sandbox vault
// Additionally, you will need to point that sandbox inbound routes to the sandbox vault url
// that uses ngrok to redirect to your local server
export const vgsDomain = () => {
  if (process.env.REACT_APP_DEMO) return 'https://dev.creatorpay.co';
  return process.env.NODE_ENV === 'development'
    ? 'http://localhost:2050'
    : 'https://tntadjfmvfd.live.verygoodproxy.com';
};

export const handleError = async (res, convertToJSON = true) => {
  let json;
  if (res.status > 399) {
    try {
      json = await res.json();
      return Promise.reject(new Error(json.message));
    } catch (err) {
      return Promise.reject(new Error('status code out of range'));
    }
  }

  if (convertToJSON) {
    try {
      json = await res.json();
    } catch (err) {
      return {};
    }
    return json;
  } else {
    return res;
  }
};
