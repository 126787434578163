import { createSlice, createSelector } from '@reduxjs/toolkit';
import {
  getWallets as getWalletsAPI,
  getTxs as getTxsAPI,
  onboardWallet as onboardWalletAPI,
  getWalletDetails as getWalletDetailsAPI,
} from 'api/wallets';
import {
  getGenericStarted,
  getGenericFailure,
  getPayloadSuccess,
  getGenericSuccess,
  getGenericState,
  handleError,
} from './sliceUtils';
import { getOrg } from 'slices/org';

export const initialwalletsState = {
  wallets: getGenericState({}),
  walletDetails: getGenericState({}),
  onboardWallet: getGenericState(),
  txs: getGenericState({}),
};

export const walletsSlice = createSlice({
  name: 'wallets',
  initialState: initialwalletsState,
  reducers: {
    getWalletsStarted: getGenericStarted('wallets'),
    getWalletsSuccess: getPayloadSuccess('wallets'),
    getWalletsFailure: getGenericFailure('wallets'),

    getWalletDetailsStarted: getGenericStarted('walletDetails'),
    getWalletDetailsSuccess: getPayloadSuccess('walletDetails'),
    getWalletDetailsFailure: getGenericFailure('walletDetails'),

    getTxsStarted: getGenericStarted('txs'),
    getTxsSuccess: (state, action) => {
      state.txs.loading = false;
      state.txs.error = '';
      state.txs.data[action.payload.type] = action.payload.data;
    },
    getTxsFailure: getGenericFailure('txs'),

    onboardWalletStarted: getGenericStarted('onboardWallet'),
    onboardWalletSuccess: getGenericSuccess('onboardWallet'),
    onboardWalletFailure: getGenericFailure('onboardWallet'),
  },
});

export const {
  getWalletsStarted,
  getWalletsSuccess,
  getWalletsFailure,

  getWalletDetailsStarted,
  getWalletDetailsSuccess,
  getWalletDetailsFailure,

  getTxsStarted,
  getTxsSuccess,
  getTxsFailure,

  onboardWalletStarted,
  onboardWalletFailure,
  onboardWalletSuccess,
} = walletsSlice.actions;

export default walletsSlice.reducer;

export const getWallets = () => async (dispatch, getState) => {
  dispatch(getWalletsStarted());
  try {
    const res = await getWalletsAPI(getState());
    dispatch(getWalletsSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getWalletsFailure,
      'There was an issue retrieving your Wallets'
    );
  }
};

export const getWalletDetails = (type) => async (dispatch, getState) => {
  dispatch(getWalletDetailsStarted());
  try {
    const res = await getWalletDetailsAPI(getState(), type);
    dispatch(getWalletDetailsSuccess(res));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getWalletDetailsFailure,
      'There was an issue retrieving your Wallets'
    );
  }
};

export const getTxs = (type, params) => async (dispatch, getState) => {
  dispatch(getTxsStarted());
  try {
    const res = await getTxsAPI(getState(), type, params);
    dispatch(getTxsSuccess({ data: res, type }));
  } catch (err) {
    handleError(
      err,
      dispatch,
      getTxsFailure,
      'There was an issue retrieving your Transactions'
    );
  }
};

export const onboardWallet =
  (type, step, data, cb) => async (dispatch, getState) => {
    dispatch(onboardWalletStarted());
    try {
      const res = await onboardWalletAPI(getState(), type, step, data);
      dispatch(onboardWalletSuccess(res));
      dispatch(getOrg());
      !!cb && cb();
    } catch (err) {
      handleError(
        err,
        dispatch,
        onboardWalletFailure,
        'There was an issue retrieving your Wallets'
      );
    }
  };

// selectors
const selectWallets = (state) => state.wallets;

export const walletsSelector = createSelector(
  selectWallets,
  (walletsState = {}) => walletsState.wallets || getGenericState({})
);

export const totalBalanceSelector = createSelector(
  walletsSelector,
  (walletsState = {}) => {
    const { data } = walletsState;
    const wallets = Object.values(data);
    return wallets.reduce((agg, wallet) => {
      const usdBal = (wallet.balances || []).find(
        (bal) => bal.currency === 'USD'
      ) || {
        amount: 0,
      };
      return agg + usdBal.amount;
    }, 0);
  }
);

export const niumWalletSelector = createSelector(
  walletsSelector,
  (walletsState = {}) => {
    const niumWallet = (walletsState.data || {}).nium;
    return niumWallet;
  }
);

export const stripeWalletSelector = createSelector(
  walletsSelector,
  (walletsState = {}) => {
    const stripeWallet = (walletsState.data || {}).stripe;
    return stripeWallet;
  }
);

export const niumWalletBalanceSelector = createSelector(
  niumWalletSelector,
  (wallet) => {
    if (!wallet) return 0;
    return wallet.balances.find((bal) => bal.currency === 'USD');
  }
);

export const stripeWalletBalanceSelector = createSelector(
  stripeWalletSelector,
  (wallet) => {
    if (!wallet) return 0;
    return wallet.balances.find((bal) => bal.currency === 'USD');
  }
);

export const paypalWalletSelector = createSelector(
  walletsSelector,
  (walletsState = {}) => {
    const paypalWallet = (walletsState.data || {}).paypal;
    return paypalWallet;
  }
);

export const onboardWalletSelector = createSelector(
  selectWallets,
  (walletsState = {}) => walletsState.onboardWallet || getGenericState()
);

export const txsSelector = createSelector(
  selectWallets,
  (walletsState = {}) => {

    return walletsState.txs || getGenericState({})
  }
);

export const niumTxsSelector = createSelector(txsSelector, (txsState) => {
  const data = txsState.data || {};
  return data.nium || [];
});

export const stripeTxsSelector = createSelector(txsSelector, (txsState) => {
  const data = txsState?.data || {};
  return data.stripe || [];
});

export const walletDetailsSelector = createSelector(
  selectWallets,
  (walletsState = {}) => walletsState.walletDetails || getGenericState({})
);

