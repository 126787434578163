import React, { useState, useEffect, useRef } from 'react';
import css from 'classnames';
import { Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  walletsSelector,
  stripeWalletSelector,
  stripeWalletBalanceSelector,
  txsSelector,
  stripeTxsSelector,
  getTxs,
} from 'slices/wallets';
import TransferFundsModal from './TransferFundsModal';
import DepositFundsModal from './AddFundsModal';
import LoadingOverlay from 'components/LoadingOverlay';
import Card from 'components/Card';
import DataGrid from 'components/DataGrid';
import TableHeader from 'components/TableHeader';
import Button from 'components/Button';
import { flowTypeLabels } from 'constants/labelMaps';
import { getDateAndTimeFromEpochSec } from 'util/time';
import { renderPaymentStatusChip } from 'util/payments';
import { formatCurrency } from 'util/renderStrings';
import global from 'styles/global';
import { paymentStatusValueMap } from 'util/table';

const columns = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    renderCell: (params) => getDateAndTimeFromEpochSec(params.value),
  },
  {
    field: 'memo',
    headerName: 'Description',
    flex: 2,
    renderCell: (params) => {
      const paymentId = params.row?.metadata?.payment_id;
      return paymentId ? (
        <Link to={`/payments/${paymentId}`}>{params.value}</Link>
      ) : (
        params.value
      );
    },
  },
  {
    field: 'flow_type',
    headerName: 'Flow type',
    flex: 1,
    valueGetter: (params) => flowTypeLabels[params.row.flow_type] || '-'
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    valueGetter: (params) => paymentStatusValueMap[params.value],
    renderCell: (params) => renderPaymentStatusChip(params.row.status),
  },
  {
    field: 'amount',
    headerName: 'Amount',
    renderCell: (params) => formatCurrency(params.value),
    flex: 1,
  },
];

export default function UsTab({ walletData = {} }) {
  const [showDetails, setShowDetails] = useState(false);

  // pagination state management
  // sotring page ids in an index 
  // easier  than juggeling prev, curr, and next id
  // we paginate by storing all the page data in an array. 
  // turning to a new page? append it to the paginateHistory array
  const [paginationHistory, setPaginationHistory] = useState([{ page: 0, next_page: 0 }]);
  // we use the historyIndex as the current pointer to 
  // 1. keep track of where we are in the array 
  // 2. increment the max pages if the pointer bumps up against the page history and has_more
  const [historyIndex, setHistoryIndex] = useState(0);
  const [displayPage, setDisplayPage] = useState(0);
  const [rowCount, setRowCount] = useState(0)

  const [transferFundsModalVisible, setTransferFundsModalVisible] =
    useState(false);
  const [depositFundsModalVisible, setDepositFundsModalVisible] =
    useState(false);

  const dispatch = useDispatch();

  // use destructuring with default values to handle undefined selectors
  const { loading: walletsLoading = false } = useSelector(walletsSelector) || {};
  const { loading: txsLoading = false } = useSelector(txsSelector) || {};

  const stripeWallet = useSelector(stripeWalletSelector);
  const { payment_method, setup_intent } = stripeWallet || {};
  const txData = useSelector(stripeTxsSelector) || { has_more: false, page: 0, transactions: [] };
  const usdBalance = useSelector(stripeWalletBalanceSelector);
  const loading = walletsLoading || txsLoading;

  const g = global();

  // reference to track previous page Id
  const prevPageIdRef = useRef(null);

  // fetch transactions when the current page identifier changes
  useEffect(() => {
    // get current pagination params from history
    const currentPaginationParams = paginationHistory[historyIndex];
    const currentPageId = currentPaginationParams?.page;

    // only fetch if the page id changed
    if (currentPageId !== undefined && currentPageId !== prevPageIdRef.current) {
      prevPageIdRef.current = currentPageId;
      dispatch(getTxs('stripe', { page: currentPageId }));
    }



  }, [historyIndex, dispatch]);

  useEffect(() => {
    // no txs loaded in state
    if (!txData?.transactions?.length) {
      return
    }

    // defaults to length of 1 in state
    const onlyPage = paginationHistory?.length === 1
    const txs = txData.transactions.length
    if (onlyPage) {
      const count = txData.has_more ? txs * 2 : txs
      setRowCount(count)
      return
    }


    let alreadyCounted = false
    if (historyIndex < paginationHistory.length - 1 && !onlyPage) {
      alreadyCounted = true
    }

    // traversing the body of the array does not increment or dec 'x of n ' count
    if (alreadyCounted || !txData.has_more) {
      return
    }

    // new page! increment!
    setRowCount(prevCount => prevCount += txData.transactions.length)

  }, [txData.page])
  // update pagination history when new data arrives
  useEffect(() => {
    if (txData?.transactions?.length && txData?.page) {
      const updatedHistory = [...paginationHistory];

      const currentEntry = updatedHistory[historyIndex];
      if (currentEntry && (currentEntry.next_page !== txData.page || currentEntry.has_more !== txData.has_more)) {
        updatedHistory[historyIndex] = {
          ...currentEntry,
          has_more: txData.has_more,
          next_page: txData.page
        };

        setPaginationHistory(updatedHistory);
      }
    }
  }, [txData?.page, txData?.has_more, txData?.transactions]);

  // handle pagination from the DataGrid component
  const handlePageChange = (newPage) => {
    // prevent processing if same page or invalid
    if (newPage === displayPage || newPage === undefined || newPage === null) {
      return;
    }

    // determine direction: forward or backward
    // 1 === forward 
    // -1 === backward
    const direction = newPage > displayPage ? 1 : -1;

    // forward navigation
    if (direction === 1) {
      const currentEntry = paginationHistory[historyIndex];

      // only navigate forward if we have more results
      if (currentEntry.has_more) {
        // only increment the page if the last page will become the current page 
        if (historyIndex === paginationHistory.length - 1) {
          // add new entry to history
          setPaginationHistory([
            ...paginationHistory,
            {
              page: currentEntry.next_page,
            }
          ]);
        }

        // increment pointer
        setHistoryIndex(historyIndex + 1);
        setDisplayPage(newPage);
      }
    } else {
      // backward navigation - can only go back if we're not at the beginning
      if (historyIndex >= 0) {
        setHistoryIndex(historyIndex - 1);
        setDisplayPage(newPage);
      }
    }
  };


  let buttonText = 'Connect bank';
  if (!!payment_method) {
    buttonText = 'Add funds';
  } else if (!!setup_intent) {
    buttonText = 'Verify account';
  }



  return (
    <>
      <LoadingOverlay open={loading} />

      <TransferFundsModal
        open={transferFundsModalVisible}
        onClose={() => setTransferFundsModalVisible(false)}
        currentBalance={usdBalance}
      />

      <DepositFundsModal
        open={depositFundsModalVisible}
        onClose={() => setDepositFundsModalVisible(false)}
      />

      {!stripeWallet && (
        <div className={css(g.centerChildren, g.p_xxl)}>
          <Typography variant="h2" style={{ marginBottom: '16px' }}>
            Your US-only Wallet is not setup
          </Typography>
          <Link to="/createFundAcct">Set it up now</Link>
        </div>
      )}

      {!!stripeWallet && (
        <div>
          <div className={g.mb_lg}>
            <Card noHeader variant="outlined">
              <Grid container spacing={2}>
                <Grid item md={2} sm={12}>
                  <Typography variant="h6">ACCOUNT BALANCE</Typography>
                  <Typography variant="h2" className={g.cashGreen1}>
                    {formatCurrency(usdBalance?.amount)}
                  </Typography>
                </Grid>

                {showDetails ? (
                  <>
                    <Grid item md={3} sm={12}>
                      <Typography variant="subtitle2">Account:</Typography>
                      <Typography variant="body2">
                        {walletData.account_details.account_number}
                      </Typography>
                    </Grid>

                    <Grid item md={3} sm={12}>
                      <Typography variant="subtitle2">Routing:</Typography>
                      <Typography variant="body2">
                        {walletData.account_details.routing_number}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <Grid item md={6} sm={12}>
                    <Button type="submit" onClick={() => setShowDetails(true)}>
                      View account details
                    </Button>
                  </Grid>
                )}

                <Grid item md={4} sm={12} className={g.textRight}>
                  <Button
                    className={g.mb_xs}
                    type="submit"
                    variant="outlined"
                    color="primary"
                    onClick={() => setTransferFundsModalVisible(true)}
                  >
                    Transfer funds
                  </Button>
                  <Button
                    className={css(g.mb_xs, g.ml_xs)}
                    onClick={() => setDepositFundsModalVisible(true)}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {buttonText}
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </div>
          {/* Transactions table */}
          <TableHeader title="Transactions" />
          <div className={g.tableWrapper}>
            <DataGrid
              variant="outlined"
              autoHeight
              autoPageSize
              rowsPerPageOptions={[]}
              rows={txData?.transactions || []} // if no txs state, pass in empty array
              columns={columns}
              // server-side pagination props
              paginationMode="server"
              onPageChange={handlePageChange}
              page={displayPage}
              rowCount={rowCount}
            />
          </div>
        </div>
      )}
    </>
  );
}
