import React from 'react';

import Card from 'components/Card';
import KeyValuePair from 'components/KeyValuePair';
import { renderPaymentMethodName } from 'util/renderStrings';
import { getDateAndTimeFromIso } from 'util/time';
import objectStates from 'constants/objectStates';
import { renderPaymentStatusChip } from 'util/payments';
import global from 'styles/global';

function Info({ payment, details }) {
  const g = global();

  // delete flow_details from details
  if (details) {
    delete details.flow_details;
  }

  const renderStatus = (status) => {
    if (status === objectStates.failed) {
      return (
        <KeyValuePair
          label="Date failed"
          value={getDateAndTimeFromIso(payment.date_failed)}
        />
      );
    }

    if (status === objectStates.succeeded) {
      return (
        <KeyValuePair
          label="Date posted"
          value={
            payment.date_posted
              ? getDateAndTimeFromIso(payment.date_posted)
              : '-'
          }
        />
      );
    }

    if (status === objectStates.returned) {
      return (
        <KeyValuePair
          label="Date returned"
          value={getDateAndTimeFromIso(payment.date_failed)}
        />
      );
    }
  };

  return (
    <Card title="Info">
      <KeyValuePair
        label="Status"
        value={renderPaymentStatusChip(details.status || payment.status)}
        valueClasses={g.p_xs}
      />
      <KeyValuePair
        label="Method"
        value={renderPaymentMethodName(payment.method)}
      />
      <KeyValuePair
        label="Date created"
        value={
          payment.date_initiated
            ? getDateAndTimeFromIso(payment.date_initiated)
            : '-'
        }
      />
      {renderStatus(details.status || payment.status)}
    </Card>
  );
}

export default Info;
