import React, { useMemo, useState } from 'react';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    border: 0,
  },
  cell: {
    borderBottom: 'none !important',
  },
  wrapper: {
    flexGrow: 1,
  },
  columnHeader: {
    color: theme.palette.shades.jetBlack,
    textTransform: 'uppercase',
    fontSize: '13px',
    // the fontWeight property was not carrying to the columnHeaderTitle component even with !important so I needed to separate it out
    '& > .MuiDataGrid-columnHeaderDraggableContainer > .MuiDataGrid-columnHeaderTitleContainer  > .MuiDataGrid-columnHeaderTitle':
    {
      fontWeight: '600',
    },
    '& > .MuiDataGrid-columnHeaderDraggableContainer > .MuiDataGrid-columnHeaderTitleContainer':
    {
      padding: 0,
    },
    '& > .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
  },
}));

const DataGrid = ({ ...props }) => {
  const classes = useStyles();
  const [redirectState, setRedirectState] = useState({
    redirectTo: null,
    state: null,
  });

  const { columns, rows, suppressMemoization, columnDependencies = [] } = props;

  const navigateToPayment = (data) => {
    const type = data?.row?.flow_type
    if (!type) {
      return
    }
    const tx = data.row.flow_details[type]
    const { payment_id } = tx.metadata;
    if (payment_id) {
      setRedirectState({
        pathname: `/payments/${payment_id}`,
        state: { ...data.row },
      });
    }
  };

  const memoizedColumns = useMemo(() => {
    return columns;
  }, [rows, ...columnDependencies]);

  const memoizedRows = useMemo(() => {
    return rows;
  }, [rows]);

  return (
    <>
      {redirectState.pathname && (
        <Redirect
          to={{
            pathname: redirectState.pathname,
            state: { data: redirectState.state },
          }}
        />
      )}
      <MuiDataGrid
        pageSize={rows.length === 100 ? rows.length : 50}
        {...props}
        columns={suppressMemoization ? columns : memoizedColumns}
        rows={suppressMemoization ? rows : memoizedRows}
        classes={{
          root: classes.root,
          cell: classes.cell,
          columnHeader: classes.columnHeader,
        }}
        onRowClick={(data) => navigateToPayment(data)}
      />
    </>
  );
};

export default DataGrid;
