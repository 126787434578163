import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import css from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import objectStates from 'constants/objectStates';

import PaymentConfirmationModal from 'components/PaymentConfirmationModal';
import global from 'styles/global';
import Button from 'components/Button';
import TableHeader from 'components/TableHeader';

import { formatCurrency } from 'util/renderStrings';
import { formatCreatorName } from 'util/renderStrings';
import PaymentsTable from 'components/PaymentsTable';
import {
  currentUserSelector,
} from 'slices/user';
import {
  initiatePayments as initiatePaymentsSlice,
  initiatePaymentsSelector,
  remindPaymentsSelector,
  paymentsDictSelector,
  remindPayments,
  paymentsByRecipientSelector,
} from 'slices/payments';
import { outboundPmtFeesSelector } from 'slices/orgFees';
import { hasTreasurySelector } from 'slices/org';

const useStyles = makeStyles((theme) => ({
  value: {
    fontWeight: 600,
    color: theme.palette.primary.cashGreen089,
  },
  label: { fontSize: '10px', textTransform: 'uppercase' },
}));

const PaymentsTab = ({
  initiatePayments,
  initiatePaymentsState,
  paymentsDict,
  outboundPaymentFees,
  hasTreasury,
  remindPayments,
  remindPaymentsState,
  recipient,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const g = global();
  const { loading } = remindPaymentsState;

  const { id } = useParams();
  const { data: user } = useSelector(currentUserSelector);

  const pmtsForCreator = useSelector((state) =>
    paymentsByRecipientSelector(state, id)
  );

  const [selectedPayments, setSelectedPayments] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const executePayments = () => {
    initiatePayments(selectedPayments, () => {
      setModalVisible(false);
    });
  };

  const sendReminder = () => {
    remindPayments(selectedPayments, 'email');
  };

  const disableExecute = (selectedPayments) => {
    const draftPayments = pmtsForCreator.filter(
      (pmt) => pmt.status === objectStates.draft
    );
    if (selectedPayments.length === 0 || draftPayments.length === 0) {
      return true;
    }
    let disabled = false;
    selectedPayments.forEach((payment) => {
      const draftPaymentsIDs = draftPayments.map((p) => p.id);
      if (!draftPaymentsIDs.includes(payment)) {
        disabled = true;
      }
    });
    return disabled;
  };

  const disableReminder = (selectedPayments) => {
    const requireInfoPayments = pmtsForCreator.filter(
      (pmt) => pmt.status === objectStates.requires_info
    );

    if (selectedPayments.length === 0 || requireInfoPayments.length === 0) {
      return true;
    }
    let disabled = false;
    selectedPayments.forEach((payment) => {
      const requireInfoPaymentIDs = requireInfoPayments.map((p) => p.id);
      if (!requireInfoPaymentIDs.includes(payment)) {
        disabled = true;
      }
    });
    return disabled;
  };

  const totalPmtAmt = pmtsForCreator.reduce((agg, pmt) => pmt.amount + agg, 0);
  return (
    <div>
      <PaymentConfirmationModal
        open={modalVisible}
        handleClose={() => setModalVisible(false)}
        executePayments={executePayments}
        payments={selectedPayments}
        paymentsDict={paymentsDict}
        outboundPaymentFees={outboundPaymentFees}
        initiatePaymentsState={initiatePaymentsState}
      />

      <div className={g.mb_lg}>
        <Typography variant="h6" className={classes.title} gutterBottom>
          Total payment amount
        </Typography>

        <Typography
          component="h6"
          variant="h2"
          className={classes.value}
          gutterBottom
        >
          {formatCurrency(totalPmtAmt)}
        </Typography>
      </div>

      <TableHeader title={`Payments to ${formatCreatorName(recipient)}`}>
        <div>
          <Button
            size="small"
            onClick={sendReminder}
            loading={loading}
            disabled={disableReminder(selectedPayments)}
            type="submit"
            variant="outlined"
            color="primary"
          >
            Send email reminder
          </Button>
          <Button
            size="small"
            variant="outlined"
            className={g.ml_xs}
            color="primary"
            onClick={() => setModalVisible(true)}
            disabled={
              disableExecute(selectedPayments) || user.role === 'collaborator'
            }
          >
            Execute Payments
          </Button>
          <Button
            size="small"
            variant="contained"
            className={g.ml_xs}
            color="primary"
            onClick={() =>
              history.push(`/payments/newPayment?recipient_id=${recipient.id}`)
            }
            disabled={!hasTreasury}
          >
            New Payment
          </Button>
        </div>
      </TableHeader>

      <PaymentsTable
        payments={pmtsForCreator}
        columnsToDisplay={[
          'id',
          'payment_group_id',
          'status',
          'memo',
          'amount',
          'last_updated'
        ]}
        checkboxEnabled
        onSelectionModelChange={setSelectedPayments}
        isRowSelectable={(params) =>
          params.row.status === objectStates.draft ||
          params.row.status === objectStates.requires_info
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  initiatePaymentsState: initiatePaymentsSelector(state),
  paymentsDict: paymentsDictSelector(state),
  outboundPaymentFees: outboundPmtFeesSelector(state),
  hasTreasury: hasTreasurySelector(state),
  remindPaymentsState: remindPaymentsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  initiatePayments: (data, cb) => dispatch(initiatePaymentsSlice(data, cb)),
  remindPayments: (ids, method) => dispatch(remindPayments(ids, method)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsTab);
